"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-32f1a1ce"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "selected-item" };
const naive_ui_1 = require("naive-ui");
const material_1 = require("@vicons/material");
exports.default = (0, vue_1.defineComponent)({
    props: {
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ["close-click"],
    setup(__props, { emit }) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.renderSlot)(_ctx.$slots, "default"),
                (!__props.readOnly)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(naive_ui_1.NIcon), {
                        key: 0,
                        class: "close-btn",
                        onClick: _cache[0] || (_cache[0] = ($event) => (emit('close-click')))
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createVNode)((0, vue_2.unref)(material_1.CloseOutlined))
                        ]),
                        _: 1
                    }))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
