"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateViewCourseTrainingData = exports.generateViewExamData = exports.generateViewHomeworkData = exports.generateViewTeachingLearningData = exports.generateViewCourseLearningData = exports.generatePreviewCourseTrainingData = exports.generatePreviewExamData = exports.generatePreviewHomeworkData = exports.generatePreviewResourceData = exports.generatePreviewTrainingLearningData = exports.generatePreviewTeachingLearningData = exports.generatePreviewCourseLearningData = void 0;
// @ts-ignore
const utils_js_1 = require("../../../utils/utils.js");
function generatePreviewCourseLearningData(list) {
    return list.map(({ title, key, model_list, section_list }) => ({
        id: key,
        title,
        show: true,
        course_material_chapter_type_list: model_list.map(({ title, list }) => ({
            title,
            chapter_type_source_list: list.map(({ base_name, url }) => ({
                base_name,
                list: {
                    extension: (0, utils_js_1.getExtWithDotFromFileName)(base_name),
                    file_name: base_name,
                    file_url: url,
                    video_id: url
                }
            }))
        })),
        section_list: section_list.map(({ title, key, model_list }) => ({
            id: key,
            title,
            material_section_type_list: model_list.map(({ title, list }) => ({
                title,
                type_source_list: list.map(({ base_name, url }) => ({
                    base_name,
                    list: {
                        extension: (0, utils_js_1.getExtWithDotFromFileName)(base_name),
                        file_name: base_name,
                        file_url: url,
                        video_id: url
                    }
                }))
            }))
        }))
    }));
}
exports.generatePreviewCourseLearningData = generatePreviewCourseLearningData;
function generatePreviewTeachingLearningData(list) {
    return list.map(({ title, key, model_list, section_list }) => ({
        id: key,
        title,
        course_material_chapter_type_list: model_list.map(({ title, list }) => ({
            title,
            chapter_type_source_list: list.map(({ base_name, url }) => ({
                base_name,
                list: {
                    extension: (0, utils_js_1.getExtWithDotFromFileName)(base_name),
                    file_name: base_name,
                    file_url: url,
                    video_id: url
                }
            }))
        })),
        section_list: section_list.map(({ title, key, model_list }) => ({
            id: key,
            title,
            material_section_type_list: model_list.map(({ title, list }) => ({
                title,
                type_source_list: list.map(({ base_name, ids }) => ({
                    base_name,
                    list: {
                        title: base_name,
                        id: ids,
                        introduce: '',
                    },
                    source_id: ids
                }))
            }))
        }))
    }));
}
exports.generatePreviewTeachingLearningData = generatePreviewTeachingLearningData;
function generatePreviewTrainingLearningData(list, scorePointIDNameMap) {
    return list.map(({ title, key, section_list }) => ({
        id: key,
        title,
        show: true,
        section_list: section_list.map(({ title, key, introduce, scoring_points }) => ({
            id: key,
            title,
            introduce,
            scoring_points: scoring_points.join(','),
            scoring_points_names: scoring_points.map((id) => ({ name: scorePointIDNameMap[id] })),
        }))
    }));
}
exports.generatePreviewTrainingLearningData = generatePreviewTrainingLearningData;
function generatePreviewResourceData(list) {
    return list.map(({ title, is_folder, fileURL, data, is_show, is_download }) => ({
        title,
        is_folder,
        file_url: fileURL,
        child: generatePreviewResourceData(data),
        is_show: Number(is_show),
        is_download: Number(is_download)
    }));
}
exports.generatePreviewResourceData = generatePreviewResourceData;
function generatePreviewHomeworkData(list) {
    return list.map(({ title, paper_id, exerciseNum }) => ({
        title: title.trim() ? title : '（未命名作业）',
        paper_id,
        paper_manual_num: exerciseNum
    }));
}
exports.generatePreviewHomeworkData = generatePreviewHomeworkData;
function generatePreviewExamData(list) {
    return list.map(({ title, paper_id, exerciseNum }) => ({
        title: title.trim() ? title : '（未命名考试）',
        paper_id,
        paper_manual_num: exerciseNum
    }));
}
exports.generatePreviewExamData = generatePreviewExamData;
function generatePreviewCourseTrainingData(list) {
    return list.map(({ title, fileURL }) => ({
        title,
        fileURL
    }));
}
exports.generatePreviewCourseTrainingData = generatePreviewCourseTrainingData;
function generateViewCourseLearningData(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.map(({ id, title, material_chapter_type_list, section_list }) => ({
        id,
        title,
        show: true,
        course_material_chapter_type_list: Array.isArray(material_chapter_type_list)
            ? material_chapter_type_list.map(({ title, chapter_type_source_list }) => ({
                title,
                chapter_type_source_list: Array.isArray(chapter_type_source_list)
                    ? chapter_type_source_list.map(({ base_name, source_info, data_type, source_id }) => ({
                        base_name,
                        list: source_info,
                        data_type,
                        source_id
                    }))
                    : []
            }))
            : [],
        section_list: Array.isArray(section_list)
            ? section_list.map(({ id, title, material_section_type_list }) => ({
                id,
                title,
                material_section_type_list: Array.isArray(material_section_type_list)
                    ? material_section_type_list.map(({ title, type_source_list }) => ({
                        title,
                        type_source_list: Array.isArray(type_source_list)
                            ? type_source_list.map(({ base_name, source_info, data_type, source_id }) => ({
                                base_name,
                                list: source_info,
                                data_type,
                                source_id
                            }))
                            : []
                    }))
                    : []
            }))
            : []
    }));
}
exports.generateViewCourseLearningData = generateViewCourseLearningData;
function generateViewTeachingLearningData(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.map(({ id, title, material_chapter_type_list, section_list }) => ({
        id,
        title,
        show: true,
        course_material_chapter_type_list: Array.isArray(material_chapter_type_list) ? material_chapter_type_list : [],
        section_list: Array.isArray(section_list) ? section_list : [],
        /*
        course_material_chapter_type_list: Array.isArray(material_chapter_type_list)
          ? material_chapter_type_list.map(({ title, chapter_type_source_list }: any) => ({
            title,
            chapter_type_source_list: Array.isArray(chapter_type_source_list)
              ? chapter_type_source_list.map(({ base_name, source_info }: any) => ({
                base_name,
                list: source_info
              }))
              : []
          }))
          : [],
        section_list: Array.isArray(section_list)
          ? section_list.map(({ id, title, material_section_type_list }: any) => ({
            id,
            title,
            material_section_type_list: Array.isArray(material_section_type_list)
              ? material_section_type_list.map(({ title, type_source_list }: any) => ({
                title,
                type_source_list: Array.isArray(type_source_list)
                  ? type_source_list.map(({ base_name, source_id, introduce }: any) => ({
                    base_name,
                    list: {
                      title: base_name,
                      id: source_id,
                      introduce
                    },
                    source_id
                  }))
                  : []
              }))
              : []
          }))
          : []
        */
    }));
}
exports.generateViewTeachingLearningData = generateViewTeachingLearningData;
function generateViewHomeworkData(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.map(({ title, paper_id, paper }) => ({
        title: title.trim() ? title : '（未命名作业）',
        paper_id,
        paper_manual_num: Array.isArray(paper) ? paper.length : 0
    }));
}
exports.generateViewHomeworkData = generateViewHomeworkData;
function generateViewExamData(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.map(({ title, paper_id, paper }) => ({
        title: title.trim() ? title : '（未命名考试）',
        paper_id,
        paper_manual_num: Array.isArray(paper) ? paper.length : 0
    }));
}
exports.generateViewExamData = generateViewExamData;
function generateViewCourseTrainingData(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.map(({ title, file_url, file_size, file_name }) => ({
        title,
        fileURL: file_url,
        fileSize: Number(file_size) || 0,
        fileName: file_name
    }));
}
exports.generateViewCourseTrainingData = generateViewCourseTrainingData;
