<template>
  <NModal
    v-model:show="modalShow"
    preset="card"
    size="small"
    title="添加实操"
    :segmented="{ content: 'hard', footer: 'hard' }"
    style="width: 1200px;"
    :mask-closable="false"
    :close-on-esc="false"
    @after-leave="clearModal"
  >
    <template #default>
      <div>
        <KnowledgeListWithSearch
          ref="knowledgeListRef"
          :otherListReqData="otherListReqData"
          labelContentHeight="calc(100vh - 300px)"
          tableContentHeight="calc(100vh - 350px)"
        />
      </div>
    </template>
    <template #footer>
      <NSpace justify="end">
        <NButton @click="closeModal">取消</NButton>
        <NButton type="primary" @click="confirmModal">确定</NButton>
      </NSpace>
    </template>
  </NModal>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { NModal } from 'naive-ui';

  import KnowledgeListWithSearch from '../KnowledgeListWithSearch/KnowledgeListWithSearch.vue';

  const emit = defineEmits(['knowledge-arr-add']);
  defineProps({
    otherListReqData: {
      type: Object,
      default: function () { return {}; }
    }
  });

  const modalShow = ref(false);
  const knowledgeListRef = ref(null);

  const openModal = args => {
    modalShow.value = true;
    nextTick(() => {
      knowledgeListRef.value.initData(args);
    });
  };

  const clearModal = () => {};
  const closeModal = () => {
    modalShow.value = false;
  };
  const confirmModal = () => {
    emit('knowledge-arr-add', knowledgeListRef.value.getSelectedTeachings());
    closeModal();
  };
  
  defineExpose({
    openModal,
    confirmModal
  });
</script>