"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTaskItem = exports.generateSubTaskItem = exports.generateSubTaskModuleItem = exports.generateSubTaskResourceItem = exports.generateChapterItem = exports.generateSectionItem = exports.generateModuleItem = exports.generateResourceItem = void 0;
// @ts-ignore
const utils_js_1 = require("@/utils/utils.js");
const system_category_type_1 = require("../../../enums/system-category-type");
const defaultChapterName = '本章名称';
const defaultSectionName = '本节名称';
const defaultModuleName = '本模块名称';
const defaultTaskName = '本任务名称';
const defaultSubTaskName = '本子任务名称';
function generateResourceItem({ name, id, url, type }) {
    return {
        type: type || 3,
        ids: id,
        base_name: name,
        url
    };
}
exports.generateResourceItem = generateResourceItem;
function generateModuleItem({ name, resourceList }) {
    return {
        title: name || defaultModuleName,
        sort: 1,
        list: Array.isArray(resourceList) ? resourceList.map(item => generateResourceItem(item)) : []
    };
}
exports.generateModuleItem = generateModuleItem;
function generateDefaultModuleList() {
    const systemTypeDefaultModuleNameListMap = {};
    systemTypeDefaultModuleNameListMap[system_category_type_1.systemCategoryTypeVal.COURSE] = [
        // { name: '教材' },
        { name: '课件' },
        { name: '微课' },
        // { name: '实训' },
        // { name: '习题' }
    ];
    systemTypeDefaultModuleNameListMap[system_category_type_1.systemCategoryTypeVal.TEACHING] = [
        { name: '导入' },
        { name: '任务情景设置' },
        { name: '任务一学习' },
        { name: '任务二学习' },
        { name: '实操演练' },
    ];
    return systemTypeDefaultModuleNameListMap[window.localStorage.getItem('curBuildSystemType')]
        .map((item) => generateModuleItem(item));
}
function generateSectionItem({ name, moduleList } = {}) {
    return {
        key: `2-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultSectionName,
        section_title: name || defaultSectionName,
        sort: 1,
        model_list: Array.isArray(moduleList) ? moduleList.map(item => generateModuleItem(item)) : [...generateDefaultModuleList()]
    };
}
exports.generateSectionItem = generateSectionItem;
function generateChapterItem({ name, moduleList, sectionList } = {}) {
    return {
        key: `1-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultChapterName,
        chapter_title: name || defaultChapterName,
        sort: 1,
        model_list: Array.isArray(moduleList) ? moduleList.map(item => generateModuleItem(item)) : [...generateDefaultModuleList()],
        section_list: Array.isArray(sectionList) ? sectionList.map(item => generateSectionItem(item)) : [generateSectionItem({})]
    };
}
exports.generateChapterItem = generateChapterItem;
function generateSubTaskResourceItem({ name, id, url }) {
    return {
        type: 4,
        ids: id,
        base_name: name,
        url
    };
}
exports.generateSubTaskResourceItem = generateSubTaskResourceItem;
function generateSubTaskModuleItem({ name, resourceList }) {
    return {
        title: name || defaultModuleName,
        sort: 1,
        list: Array.isArray(resourceList) ? resourceList.map(item => generateSubTaskResourceItem(item)) : []
    };
}
exports.generateSubTaskModuleItem = generateSubTaskModuleItem;
function generateDefaultSubTaskModuleList() {
    return [
        { name: defaultModuleName },
    ].map(item => generateSubTaskModuleItem(item));
}
function generateSubTaskItem({ name, moduleList } = {}) {
    return {
        key: `2-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultSubTaskName,
        section_title: name || defaultSubTaskName,
        sort: 1,
        model_list: Array.isArray(moduleList) ? moduleList.map(item => generateSubTaskModuleItem(item)) : [...generateDefaultSubTaskModuleList()]
    };
}
exports.generateSubTaskItem = generateSubTaskItem;
function generateTaskItem({ name, moduleList, sectionList } = {}) {
    return {
        key: `1-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultTaskName,
        chapter_title: name || defaultTaskName,
        sort: 1,
        model_list: Array.isArray(moduleList) ? moduleList.map(item => generateSubTaskModuleItem(item)) : [...generateDefaultSubTaskModuleList()],
        section_list: Array.isArray(sectionList) ? sectionList.map(item => generateSubTaskItem(item)) : [generateSubTaskItem({})]
    };
}
exports.generateTaskItem = generateTaskItem;
