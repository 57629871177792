"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-7548eee2"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = ["onDragover", "onDrop"];
const vue_3 = require("vue");
const naive_ui_1 = require("naive-ui");
// @ts-ignore
const file_type_js_1 = require("@/enumerators/file-type.js");
// @ts-ignore
const utils_js_1 = require("@/utils/utils.js");
exports.default = (0, vue_1.defineComponent)({
    props: {
        accept: {
            type: String,
            default: ''
        },
        maxBytesSize: {
            type: Number,
            default: 0
        },
        generalFileUpload: {
            type: Boolean,
            default: false
        }
    },
    emits: ["file-list-select"],
    setup(__props, { emit }) {
        const props = __props;
        const message = (0, naive_ui_1.useMessage)();
        const tipsDisplay = (0, vue_3.ref)(false);
        let dragFilesOver = false;
        let dragOverTimer = null;
        function folderUpload({ size, type }) {
            return size === 0 && type === '';
        }
        function handleDragOver() {
            if (!dragFilesOver) {
                dragFilesOver = true;
                tipsDisplay.value = dragFilesOver;
            }
            clearTimeout(dragOverTimer);
            dragOverTimer = setTimeout(() => {
                dragFilesOver = false;
                tipsDisplay.value = dragFilesOver;
            }, 300);
        }
        function handleDrop(e) {
            const files = e.dataTransfer.files;
            console.log(files);
            const length = files.length;
            switch (length) {
                case 0:
                    return;
                case 1:
                    if (folderUpload(files[0])) {
                        console.log('不支持文件夹上传');
                        return;
                    }
                default:
                    let extErrorMessage = '';
                    let sizeErrorMessage = '';
                    let videoFileNeedCompressErrorMessage = '';
                    const acceptList = props.accept.split(',');
                    const acceptTipsText = `有文件格式不符合要求（需要为${acceptList.join('、')}格式）`;
                    const sizeTipsText = `有文件大小不符合要求（限制${Math.round(props.maxBytesSize / 1024 / 1024)}MB）`;
                    const filteredFiles = [...files]
                        .filter((file) => !folderUpload(file))
                        .filter(({ name, size }) => {
                        const extWithDot = (0, utils_js_1.getExtWithDotFromFileName)(name).toLowerCase();
                        if (props.accept && !acceptList.includes(extWithDot)) {
                            extErrorMessage = acceptTipsText;
                            return false;
                        }
                        if (props.generalFileUpload && ['.mp4', '.3gp', '.avi', '.mkv', '.mov', '.mpg', '.rmvb', '.vob', '.wmv'].includes(extWithDot)) {
                            videoFileNeedCompressErrorMessage = '视频文件请添加至压缩包后上传';
                            return false;
                        }
                        if (props.maxBytesSize && size > props.maxBytesSize) {
                            sizeErrorMessage = sizeTipsText;
                            return false;
                        }
                        return true;
                    });
                    extErrorMessage && message.error(extErrorMessage);
                    sizeErrorMessage && message.error(sizeErrorMessage);
                    videoFileNeedCompressErrorMessage && message.error(videoFileNeedCompressErrorMessage);
                    console.log(filteredFiles);
                    emit('file-list-select', filteredFiles.map((file, index) => {
                        const extWithDot = (0, utils_js_1.getExtWithDotFromFileName)(file.name);
                        return {
                            id: `${Date.now()}${index}`,
                            // savedId,
                            name: file.name,
                            size: file.size,
                            ext: extWithDot,
                            file: file,
                            fileType: (0, file_type_js_1.getFileTypeFromExt)(extWithDot),
                            upComplete: false,
                            saved: false,
                            videoId: '',
                            fileUniqueStr: (0, utils_js_1.getFileUniqueStr)(file),
                            upPercentage: 0,
                            fileUrl: '',
                            generalFileUpload: props.generalFileUpload
                        };
                    }));
            }
        }
        function preventDropDefault(e) {
            e.preventDefault();
        }
        (0, vue_3.onMounted)(() => {
            document.addEventListener('drop', preventDropDefault, true);
            document.addEventListener('dragover', preventDropDefault, false);
        });
        (0, vue_3.onBeforeUnmount)(() => {
            clearTimeout(dragOverTimer);
            document.removeEventListener('drop', preventDropDefault, true);
            document.removeEventListener('dragover', preventDropDefault, false);
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: "file-dragger",
                onDragover: (0, vue_2.withModifiers)(handleDragOver, ["prevent"]),
                onDrop: (0, vue_2.withModifiers)(handleDrop, ["prevent"])
            }, [
                (0, vue_2.renderSlot)(_ctx.$slots, "default"),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["file-dragger__tips", { 'tips-display': tipsDisplay.value }])
                }, "释放文件上传", 2)
            ], 40, _hoisted_1));
        };
    }
});
